import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../assets/images/services/PositiveBehaviourSupport.png'
import project2 from '../../assets/images/projects/project2.jpg'
import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <h3>Behaviour Support</h3>
                            <p>Assistive technology is an umbrella term for any equipment or aid that improves functioning and independence, and supports a person with disability to reach their goals in the home, the community and the workplace. With the support of this technology, no matter what’s important to you, you can achieve, develop and learn just like everyone else.</p>

                            
                            <p>We can link your goals with the correct supports needed to achieve them. For example, you may have problems with your vision, but want to improve your ability to follow a recipe independently, to support cooking for yourself and your family. After a proper assessment, our OT may prescribe you with adaptive living equipment, such as a liquid level indicator, or talking kitchen scales, so that you can succeed in this area.</p>
                            
                            <p>Trained health professionals are needed not only for the assessment stage but also for the proper prescription, fitting, user training and follow-up of assistive technology. Once we’ve assessed your needs, we’ll trial various assistive technology options with you, to see what works best. We’ll also spend time with you to make sure it’s the right tool for the job.</p>
                                
                            <p>Below is a list of the types of technologies we typically prescribe for people with disability, to help</p>

                            

                            <h3>Aids and devices</h3>
                            <ul className="technologies-features">
                                <li><span>Bathroom rails</span></li>
                                <li><span>Easy grip Cutlery handles</span></li>
                                <li><span>Long handled combs</span></li>
                                <li><span>Car swivel seat cushions</span></li>
                                <li><span>Modified computer keyboard</span></li>
                            </ul>
                            <h3>Assistive Technology</h3>
                            <ul className="technologies-features">
                                <li><span>Wheelchair</span></li>
                                <li><span>Car seat</span></li>
                                <li><span>Pressure mattress</span></li>
                                <li><span>Sling or hoist</span></li>
                                <li><span>Complex modifications</span></li>
                                <li><span>Mobile or ceiling hoist</span></li>
                                <li><span>Reminders applications</span></li>
                            </ul>
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent